<template>
  <v-content id="page-container">
    <router-view id="content-wrap" />
    <dashboard-core-footer
      id="dashboard-core-footer"
    />
  </v-content>
</template>

<script>
  export default {
    name: 'DashboardCoreView',

    components: {
      DashboardCoreFooter: () => import('./Footer'),
    },
  }
</script>

<style scoped>
  #dashboard-core-footer {
    position: absolute;
    bottom: 0;
    height: 5rem;
    width: 100%;
  }
  #page-container {
    position: relative;
    min-height: 100vh;
  }
  #content-wrap {
    padding-bottom: 5rem;    /* Footer height */
  }  
</style>
